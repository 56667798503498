import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import ServiceDetails from "../components/service-details";
import CallToActionOne from "../components/call-to-action-one";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import Banner from "../components/banner";

class ServicesVideo extends React.Component {
    state = {
        bannerh4: "Music & Promo Videos",
        bannerh2: "",
        bannerImage: "/images/services/banner.png",
        serviceImage: "/images/services/video.png",
        serviceDescriptionTitle: "Creating Music & Promo Videos",
        serviceDescription: "At Half House Studios, we believe that music and promotional videos should be created to the highest standards. We have a team of experienced and talented professionals who are dedicated to helping you create the perfect sound and visuals for your project. Our state-of-the-art equipment and cutting-edge technology will ensure that your project is of the highest quality. We also offer a variety of services, such as mixing, mastering, and video production, so you can be sure that your project will be completed to the highest standards. With our affordable rates and friendly customer service, we make sure that working with us is an enjoyable experience. So if you're looking for a reliable and experienced team to help you create the perfect music and promotional videos, look no further than Half House Studios.",
        serviceName1: "Pre-Production",
        serviceLink1: "/services/preproduction",
        serviceName2: "Music Production & Writing",
        serviceLink2: "/services/productionandwriting",
        serviceName3: "Studio Recording",
        serviceLink3: "/services/studiorecording",
        serviceName4: "Mix & Mastering",
        serviceLink4: "/services/mixandmaster",
        serviceName5: "Release Management",
        serviceLink5: "/services/management",
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <Banner data={this.state}/>
                    <ServiceDetails data={this.state}/>
                    <CallToActionOne extraClassName="ready"/>
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        );
    };
}

export default ServicesVideo;