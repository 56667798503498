import React from "react";

const Footer = () => {
  const LogoImage = {
    light: "/images/HalfHouseLogoWhite.png",
    dark: "/images/HalfHouseLogoBlack.png"
  };
  const { light } = LogoImage;
  return (
    <footer className="footer_1" style={{background: "url(/images/bg/5.jpg) no-repeat center center / cover"}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
              <a href="/">
                <img src={light} alt="" />
              </a>
              <p>
                Half House Studios is a music label dedicated to providing a supportive and creative environment for up-and-coming artists in Champaign-Urbana, Illinois
                We aim to foster a community of musicians where creativity and collaboration are encouraged.
                We believe that music has the power to bring people together and inspire positive change.
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact</h3>
              <p>
                407 1/2 st champagne, Illinois
                <br />
                United States of America
              </p>
              {/*<p>P: 000 000 0000</p>*/}
              <p>
                E: <a href="#">contact@HalfHouseStudios.com</a>
              </p>
            </aside>
          </div>
          <div className="col-lg-3 col-sm-2 col-md-3">
            <aside className="widget social_widget">
              <h3 className="widget_title">social</h3>
              <ul>
                <li>
                  <a href="https://twitter.com/halfhousestu" target="_blank">
                    <i className="fa fa-twitter"/>Twitter
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/halfhousestu/" target="_blank">
                    <i className="fa fa-instagram"/>Instagram
                  </a>
                </li>
                <li>
                  <a href="https://www.twitch.tv/halfhousestudios" target="_blank">
                    <i className="fa fa-twitch"/>Twitch
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCLcR_JZRkJqr5f6vvFpOfKQ" target="_blank">
                    <i className="fa fa-youtube-play"/>Youtube
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()}{" "}
              <a href="#">Half House Studios</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
