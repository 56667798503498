import React, {Fragment} from "react";
import {Col, Container, Row} from "react-bootstrap";

class ArtistSoundPacks extends React.Component {
    render() {
        return (
            <section id="soundpacks" className="commonSection porfolio">
                <Container>
                    <Row>
                        <Col lg={12} className="text-center">
                            <Fragment>
                                <h2 className="sec_title">Featured Sound Packs</h2>
                            </Fragment>
                        </Col>
                    </Row>
                    <Row id="Grid">
                        <div className="custom">
                            <Row>
                                {this.props.data.SoundPackData.map((post, index) => (
                                    <Col lg={4} md={6} sm={12} key={index}>
                                        <a href={post.url}>
                                            <div className="singlefolio">
                                                    <img src={post.image} alt={post.title} />
                                                    <div className="folioHover">
                                                        <h4>
                                                            {post.title}
                                                        </h4>
                                                        {post.categories}
                                                    </div>
                                            </div>
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col lg={12} className="text-center">
                                    <a className="common_btn red_bg" href="/soundpacks">
                                        <span>View More</span>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </section>
        );
    };
}

export default ArtistSoundPacks;