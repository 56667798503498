import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, EffectFade, Navigation} from "swiper";
import {Col, Container, Row} from "react-bootstrap";

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderOne = () => {
  const sliderData = [
      {
        image: "/video/gifs/halfHouse.gif",
        subTitle: "welcome to half house",
      },
      {
        image: "/video/gifs/production.gif",
        title: "high end production on a budget"
      },
      {
        image: "/video/gifs/booth.gif",
        title: "Record in a professional booth"
      },
      {
        image: "/video/gifs/musicVideo.gif",
        title: "Music videos your fans will remember"
      },
      {
        image: "/video/gifs/resources.gif",
        title: "DAW ready sound packs"
      }
  ];
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    // effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev"
    }
    // autoplay: {
    //   delay: 5000
    // }
  };
  return (
    <section className="main-slider">
      <Swiper {...mainSlideOptions}>
        {sliderData.map(({ image, subTitle, title, button }, index) => (
          <SwiperSlide key={index}>
            <div className="image-layer" style={{backgroundImage: "url("+image+")"}}/>
            <Container>
              <Row>
                <Col lg={12} className="text-center">
                  <p className="main-slider__subtext">{subTitle}</p>
                  <h3 className="main-slider__title">{title}</h3>
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev" id="main-slider-prev">
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="swiper-button-next" id="main-slider-next">
          <i className="fa fa-angle-right"></i>
        </div>
      </Swiper>
    </section>
  );
};

export default SliderOne;
