import React, {useRef} from 'react';
import emailjs from "@emailjs/browser";

const contactArtist = (props) => {
    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_n26qqet', 'template_q08458h', form.current, '6eShF2bNpVf8OGGPq')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <section id="contact" className="commonSection ContactPage">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="sec_title text-center">Send me an email!</h2>
                        <p className="sec_desc">Your message will be sent to {props.artistEmail}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
                        <form ref={form} onSubmit={sendEmail} className="contactFrom" id="contactForm">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <input
                                        className="input-form"
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        required="true"
                                    />
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <input
                                        className="input-form"
                                        type="email"
                                        name="sender_email"
                                        id="email"
                                        placeholder="Email Address"
                                        required="true"
                                    />
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <input
                                        className="input-form"
                                        type="text"
                                        name="artist_email"
                                        id="phone"
                                        placeholder="Phone Number (optional)"
                                    />
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <input
                                        className="input-form"
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        placeholder="Subject"
                                        required="true"
                                    />
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <textarea className="input-form required" name="message" id="con_message" placeholder="Write Message" required="true"/>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <input
                                        className="input-form"
                                        type="hidden"
                                        name="artist"
                                        id="artist"
                                        value={props.artist}
                                    />
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <input
                                        className="input-form"
                                        type="hidden"
                                        name="artist_email"
                                        id="artist_email"
                                        value={props.artistEmail}
                                    />
                                </div>
                            </div>
                            <button
                                className="common_btn red_bg"
                                type="submit"
                                id="con_submit"
                            >
                                <span>Send Email</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default contactArtist;
