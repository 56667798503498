import MenuContextProvider from "../context/menu-context";
import PageContainer from "../components/PageContainer";
import HeaderOne from "../components/header-one";
import React from "react";
import ArtistImage from "../components/artistImage";
import ArtistMenu from "../components/artistMenu";
import ArtistSummary from "../components/artistSummary"
import ArtistMediaPlayer from "../components/artistMediaPlayer";
import FeaturedAlbums from "../components/featuredAlbums";
import ArtistMusicVideoSlider from "../components/artistMusicVideoSlider";
import Footer from "../components/footer";
import ContactArtist from "../components/contactArtist";
import SocialMediaBanner from "../components/socialMediaBanner";

class WhipTrip extends React.Component {
    state = {
        artist: "whiptrip6",
        artistRealName: "David Counter",
        artistEmail: "whiptrip6@halfhousestudios.com",
        backgroundImage: "/images/bg/whiptrip6.png",
        artistNameLink: "whiptrip6",
        SummaryImage: "/images/whiptrip6/summaryImage.jpg",
        spotifyArtistLink: "https://open.spotify.com/embed/artist/13v0fOzWzB0XdbyzczOgjG?utm_source=generator&theme=0",
        artistSummaryP1: "WhipTRip6 is a founding member of Half House Studios, a cooperative organization created to host a safe and hi-fi recording space for local artists. " +
            "He has a long history of music involvement, from playing drums in his school jazz band to having a rock band in high school which he had toured with at the time. ",
        artistSummaryP2: "After graduating from the University of Illinois in 2020, WhipTrip created the idea for Half House Studios with the help of Mousepad and other local artists Half House was able to blossom into the organization it is today.",
        artistPageMenu: [
            {
                name: "Releases",
                url: "#releases"
            },
            {
                name: "Videos",
                url: "#videos",
            },
            // {
            //     name: "Merch",
            //     url: "/merch"
            // },
            {
                name: "Contact",
                url: "#contact"
            }
        ],
        artistReleases: [
            {
                title: "Sunday Morning",
                image: "/images/releases/sundayMorning.png",
                url: "https://open.spotify.com/artist/13v0fOzWzB0XdbyzczOgjG?si=X4oHUi7TQzStPAiH--0P5A"
            },
            {
                title: "Vapor Dreams",
                image: "/images/releases/vaporDreams.png",
                url: "https://open.spotify.com/album/6Zoga7ZJPDr6dQk0MYebZd?si=ntd_QLQSTF-NPP07a5zv7Q"
            }
        ],
        index: 0,
        mainSlideOptions: {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: "#main-slider-next",
                prevEl: "#main-slider-prev"
            }
        },
        videoSliderData: [
            {
                key: 0,
                image: "/video/gifs/vaporDreams.gif",
                title: "Vapor Dreams Feat. Supraman",
                subTitle: "official music video",
                id: "JrexK0LbDic",
                isOpen: false
            },
            {
                key: 1,
                image: "/video/gifs/pod3.gif",
                title: "Pod Nem: Deathcon3?! What Kanye??",
                subTitle: "Pod Nem | S2 EP3",
                id: "YLS8vnHLHWg",
                isOpen: false
            }
        ],
        socialMedia: [
            {
                name: "Twitter",
                url: "https://twitter.com/whiptrip6"

            },
            {
                name: "Instagram",
                url: "https://www.instagram.com/whiptrip6/"

            },
            {
                name: "SoundCloud",
                url: "https://soundcloud.com/whiptrip6"

            }
        ]
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer >
                    <HeaderOne/>
                    <ArtistImage bgImage={this.state.backgroundImage} artist={this.state.artist}/>
                    <ArtistMenu data={this.state}/>
                    <SocialMediaBanner data={this.state.socialMedia} />
                    <ArtistSummary data={this.state}/>
                    <ArtistMediaPlayer data={this.state}/>
                    <FeaturedAlbums data={this.state}/>
                    <ArtistMusicVideoSlider data={this.state}/>
                    <ContactArtist artist={this.state.artist} artistEmail={this.state.artistEmail} />
                    <Footer />
                </PageContainer>
            </MenuContextProvider>
        )
    }
}

export default WhipTrip;