import MenuContextProvider from "../context/menu-context";
import PageContainer from "../components/PageContainer";
import HeaderOne from "../components/header-one";
import React from "react";
import ArtistImage from "../components/artistImage";
import ArtistMenu from "../components/artistMenu";
import ArtistSummary from "../components/artistSummary";
import ArtistMediaPlayer from "../components/artistMediaPlayer";
import FeaturedAlbums from "../components/featuredAlbums";
import ArtistMusicVideoSlider from "../components/artistMusicVideoSlider";
import SoundPacks from "../components/artistSoundPacks";
import Footer from "../components/footer";
import ContactArtist from "../components/contactArtist";
import SocialMediaBanner from "../components/socialMediaBanner";


class Mousepad extends React.Component {
    state = {
        artist: "Mousepad",
        artistRealName: "Josh Harris",
        artistNameLink: "mousepad",
        artistEmail: "mousepad@halfhousestudios.com",
        backgroundImage: "/images/bg/mousepad.png",
        SummaryImage: "/images/mousepad/summaryImage.jpg",
        spotifyArtistLink: "https://open.spotify.com/embed/artist/4S5BKypzX8ejWQdb4Wj0YP?utm_source=generator&theme=0",
        artistSummaryP1: "Originating from Champaign, IL. Mousepad is a producer, engineer, and artist at Half House Studios. He is proud to provide local musicians with an accessible and cost-effective place to record music. " +
            "With over six years of experience, Mousepad is responsible for the mixing and mastering songs released by Half House Studios. " +
            "Not only does he perfect the sound of your songs, but he also creates an inviting atmosphere for anyone who walks into the studio. ",
        artistSummaryP2: "Having taken three years to create, produce and master his debut album “Right Person, Wrong Time”, Mousepad is continuing to develop his sound." +
            "Since then he has released three more songs, while producing many more for the various Half House artists. Mousepad is also currently working on a collaboration album with Nick Valentine.",
        artistPageMenu: [
            {
                name: "Releases",
                url: "#releases"
            },
            {
                name: "Videos",
                url: "#videos",
            },
            {
                name: "Sound Packs",
                url: "#soundpacks",
            },
            // {
            //     name: "Merch",
            //     url: "/merch"
            // },
            {
                name: "Contact",
                url: "#contact"
            }
        ],
        artistReleases: [
            {
                title: "1ofy.u",
                image: "/images/releases/1OfU.png",
                url: "https://open.spotify.com/album/6Qwn4KNJKyrmjkn7OWrXRV?si=Da517KR-QSmcA8l9Y-zkrQ"
            },
            {
                title: "F.ne",
                image: "/images/releases/fine.png",
                url: "https://open.spotify.com/album/7nCGSUaViOtLMYW7LzLE3m?si=dhOJuXGES_-4zox3YhEhVg"
            },
            {
                title: "F.ck",
                image: "/images/releases/f.ck.png",
                url: "https://open.spotify.com/album/61xmcaLshIWsFequwSQUH7?si=ZUN0a3euR5WOS83bl1nWAQ"
            },
            {
                title: "Right Person, Wrong Time",
                image: "/images/releases/rightPersonWrongTime.png",
                url: "https://open.spotify.com/album/196RbtjYULlwDlli1GZQv8?si=U8p5tizbTxiy2SZoSqZLLA"
            },
            {
                title: "Falling Slowly",
                image: "/images/releases/fallingSlowly.png",
                url: "https://open.spotify.com/album/0BTKnKht6ocPTzbc61mc5b?si=YQQdt7QrQDKJNYjFk68VJg"
            },
            {
                title: "Let Me Take You Away",
                image: "/images/releases/letMeTakeYouAway.png",
                url: "https://open.spotify.com/album/1OH0cSEHe8TtTxIV9kuXGd?si=JFVfHg9mQfa1_efw_C5pag"
            }
        ],
        index: 0,
        options: {
            effect: "coverflow",
            spaceBetween: 25,
            loop: true,
            slidesPerView: 5,
            navigation: {
                nextEl: "#main-slider-next",
                prevEl: "#main-slider-prev"
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: true,
            },
            coverflowEffect: {
                rotate: 5,
                scale: 1,
                stretch: 0,
                depth: 10,
                modifier: 4,
                slideShadows: true,
            },
            freeMode: {
                enabled: true,
                sticky: true,
            }
        },
        videoSliderData: [
            {
                key: 1,
                image: "/video/gifs/pod3.gif",
                title: "Pod Nem: Deathcon3?! What Kanye??",
                subTitle: "Pod Nem | S2 EP3",
                id: "YLS8vnHLHWg",
                isOpen: false
            }
        ],
        SoundPackData: [
            {
                title: "Sound Pack 1",
                categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
                image: "/images/releases/soundPack1.png",
                url: "/soundpacks/1"
            },
            {
                title: "Sound Pack 2",
                categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
                image: "/images/releases/soundPack2.png",
                url: "/soundpacks/2"
            },
            {
                title: "Sound Pack 3",
                categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
                image: "/images/releases/soundPack3.png",
                url: "/soundpacks/3"
            },
            {
                title: "Sound Pack 4",
                categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
                image: "/images/releases/soundPack4.png",
                url: "/soundpacks/4"
            },
            {
                title: "Sound Pack 5",
                categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
                image: "/images/releases/soundPack5.png",
                url: "/soundpacks/5"
            },
            {
                title: "Sound Pack 6",
                categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
                image: "/images/releases/soundPack6.png",
                url: "/soundpacks/6"
            }
        ],
        socialMedia: [
            {
                name: "Instagram",
                url: "https://www.instagram.com/mousepadmusic/"

            },{
                name: "Twitter",
                url: "https://twitter.com/mousepadmusic/"

            },
            {
                name: "Youtube",
                url: "https://youtube.com/channel/UCt3zGb9yz-28HyiGtM9yaRg"

            },
            {
                name: "Spotify",
                url: "https://open.spotify.com/artist/4S5BKypzX8ejWQdb4Wj0YP?si=ZPf5eJ2vQNyKJbwnCBo_gA"

            },
            {
                name: "Apple Music",
                url: "https://music.apple.com/us/artist/mousepad/1482039968"

            },
            {
                name: "Bandcamp",
                url: "https://mousepadmusic.bandcamp.com/album/absent-minded"

            }
        ]
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer >
                    <HeaderOne/>
                    <ArtistImage bgImage={this.state.backgroundImage} artist={this.state.artist}/>
                    <ArtistMenu data={this.state}/>
                    <SocialMediaBanner data={this.state.socialMedia} />
                    <ArtistSummary data={this.state}/>
                    <ArtistMediaPlayer data={this.state}/>
                    <FeaturedAlbums data={this.state}/>
                    <SoundPacks data={this.state}/>
                    <ArtistMusicVideoSlider data={this.state}/>
                    <ContactArtist artistEmail={this.state.artistEmail} />
                    <Footer />
                </PageContainer>
            </MenuContextProvider>
        )
    }
}
export default Mousepad;