import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import Banner from "../components/banner";
import ReleasesComp from "../components/releases-comp";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";

class releasesPage extends React.Component {
    state = {
        bannerh4: "releases",
        bannerh2: "",
        bannerImage: "/images/bg/albumsBanner.png",
        halfhouseReleaseData: [
            {
                title: "Welcome To HalfHouse",
                image: "/images/releases/welcomeToTheHalfHouse.png",
                url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
            },
            {
                title: "Fuck The Rich",
                image: "/images/releases/fuckTheRich.png",
                url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
            },
            {
                title: "Lost In The Night",
                image: "/images/releases/lostInTheNight.png",
                url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
            },
            {
                title: "Find My Way",
                image: "/images/releases/findMyWay.png",
                url: "https://open.spotify.com/album/1kZLsXNUk9QFmprJnsHa1t?si=BMBcT1AZSEKLWZrmG4AeXQ"
            },
        ],
        mousepadReleaseData: [
            {
                title: "1ofy.u",
                image: "/images/releases/1OfU.png",
                url: "https://open.spotify.com/album/6Qwn4KNJKyrmjkn7OWrXRV?si=Da517KR-QSmcA8l9Y-zkrQ"
            },
            {
                title: "F.ne",
                image: "/images/releases/fine.png",
                url: "https://open.spotify.com/album/7nCGSUaViOtLMYW7LzLE3m?si=dhOJuXGES_-4zox3YhEhVg"
            },
            {
                title: "F.ck",
                image: "/images/releases/f.ck.png",
                url: "https://open.spotify.com/album/61xmcaLshIWsFequwSQUH7?si=ZUN0a3euR5WOS83bl1nWAQ"
            },
            {
                title: "Right Person, Wrong Time",
                image: "/images/releases/rightPersonWrongTime.png",
                url: "https://open.spotify.com/album/196RbtjYULlwDlli1GZQv8?si=U8p5tizbTxiy2SZoSqZLLA"
            },
            {
                title: "Falling Slowly",
                image: "/images/releases/fallingSlowly.png",
                url: "https://open.spotify.com/album/0BTKnKht6ocPTzbc61mc5b?si=YQQdt7QrQDKJNYjFk68VJg"
            },
            {
                title: "Let Me Take You Away",
                image: "/images/releases/letMeTakeYouAway.png",
                url: "https://open.spotify.com/album/1OH0cSEHe8TtTxIV9kuXGd?si=JFVfHg9mQfa1_efw_C5pag"
            },
            {
                title: "...day to day",
                image: "/images/releases/dayToDay.png",
                url: "https://open.spotify.com/album/5QBbw48bega4ORZ7JdsLB6?si=fYNsOpJoRaaWkmBh_3o_4w"
            },
            {
                title: "Quiet Night",
                image: "/images/releases/quietNight.png",
                url: "https://open.spotify.com/album/2wQRy230UAaqRGqX9vCkq2?si=Zbt30ssfTeOLaX3YkeC5ew"
            },
            {
                title: "Spent It All",
                image: "/images/releases/spentItAll.png",
                url: "https://open.spotify.com/album/69wrXFi52RpGT6Jp91guhK?si=z2Ul4YawT9mGt9SI5jmuSg"
            },
            {
                title: "Reminiscent",
                image: "/images/releases/reminiscent.png",
                url: "https://open.spotify.com/album/3E0rBseFrIa0uOvnIruQHH?si=e-7ftKM9TJivXOPQUFN_sA"
            },
            {
                title: "Adventure Is Out There",
                image: "/images/releases/adventureIsOutThere.png",
                url: "https://open.spotify.com/album/6pS7IIWvAsxdgQV04Uvj2d?si=WAvLGA5jQa6p9lM5x4OmUw"
            },
            {
                title: "Is It Reel?",
                image: "/images/releases/isItReal.png",
                url: "https://open.spotify.com/album/3E97bcjOreflvfANmc60Zc?si=1FO3avn0REWDSlevNyekEw"
            },
            {
                title: "Sightless",
                image: "/images/releases/sightless.png",
                url: "https://open.spotify.com/album/3asbsQ7RmVXVV0I6gvRGau?si=0SEbPzQASj2h52oWIeyNHQ"
            },
            {
                title: "Absent Minded",
                image: "/images/releases/absentMinded.png",
                url: "https://open.spotify.com/album/3VpsF0YvM2XG8kgWxvSTfZ?si=jBYuvyWyS1Sbp28kDXoD7w"
            },
            {
                title: "In My Mind",
                image: "/images/releases/inMyMind.png",
                url: "https://open.spotify.com/album/19ZKP70GNkavv8D31ykNuk?si=4BrjOKtSRMypssy2wxv0bg"
            },
            {
                title: "Too Much",
                image: "/images/releases/tooMuch.png",
                url: "https://open.spotify.com/album/41ygHdYcMysCaqUIw1Ij5T?si=WGYFGe2xRgui3RnPdTxBHQ"
            },
            {
                title: "Skeleton",
                image: "/images/releases/skeleton.png",
                url: "https://open.spotify.com/album/79h9duNfoteMR6EBu7BC6E?si=_p9e8HHOTaSSXUkqLUc-DA"
            },
            {
                title: "Wake Up",
                image: "/images/releases/wakeUp.png",
                url: "https://open.spotify.com/album/3wdtkpqmW4JthJWvtGDrvl?si=1V-GX7UxQiWXfWIk_uuNfw"
            },
            {
                title: "Wait...Hey!",
                image: "/images/releases/waitHey.png",
                url: "https://open.spotify.com/album/2LHaPEj9czls2kNMU68exk?si=gJLh_HOySkqQmjzDsmta_w"
            },
            {
                title: "Pollen(Save The Bees)",
                image: "/images/releases/pollenSaveTheBees.png",
                url: "https://open.spotify.com/album/3VpsF0YvM2XG8kgWxvSTfZ?si=jBYuvyWyS1Sbp28kDXoD7w"
            },
            {
                title: "Mousepad in 320p",
                image: "/images/releases/mousepadIn320p.png",
                url: "https://open.spotify.com/album/3VpsF0YvM2XG8kgWxvSTfZ?si=jBYuvyWyS1Sbp28kDXoD7w"
            }

        ],
        supramanReleaseData: [
            {
                title: "The Show Goes On",
                image: "/images/releases/TheShowGoesOn.png",
                url: "https://open.spotify.com/album/3VpsF0YvM2XG8kgWxvSTfZ?si=8Jstp7WcQLeI4070RQhuyw"
            },
            {
                title: "All Love",
                image: "/images/releases/allLove.png",
                url: "https://open.spotify.com/album/04N2T7dclHeWZYybO9tAsV?si=7D_pBDezTECi-tG0sfEt2w"
            },
            {
                title: "money.paper",
                image: "/images/releases/moneyPaper.png",
                url: "https://open.spotify.com/album/5SeCJK756MKVHYRRB7Eo6T?si=KdTg99XsT7KNyV6uICadLg"
            },
            {
                title: "Girls!",
                image: "/images/releases/girls.png",
                url: "https://open.spotify.com/album/34yQQ3oVfiI0bsMgKBHOJt?si=T-CIdG33Sbu87rHkuWz_Ug"
            },
            {
                title: "What Can I Say",
                image: "/images/releases/whatCanISay.png",
                url: "https://open.spotify.com/album/0HmoqHZfGfvqqtBiMFcJfh?si=btJdKQSSQ3CvXR-7i9xzeg"
            },
            {
                title: "Reboot",
                image: "/images/releases/reboot.png",
                url: "https://open.spotify.com/album/0IplrXRuJrmt9YyG7ArcjV?si=REdAznajTcq1oppc6ZM55g"
            },
        ],
        whiptripReleaseData: [
            {
                title: "Sunday Morning",
                image: "/images/releases/sundayMorning.png",
                url: "https://open.spotify.com/artist/13v0fOzWzB0XdbyzczOgjG?si=X4oHUi7TQzStPAiH--0P5A"
            },
            {
                title: "Vapor Dreams",
                image: "/images/releases/vaporDreams.png",
                url: "https://open.spotify.com/album/6Zoga7ZJPDr6dQk0MYebZd?si=ntd_QLQSTF-NPP07a5zv7Q"
            },
        ],
        // nickValentineReleaseData: []
        // goffMideonReleaseData: []
        // chaseGodReleaseData: []

    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <Banner data={this.state}/>
                    <ReleasesComp artistName={"Half House"} releaseData={this.state.halfhouseReleaseData}/>
                    <ReleasesComp artistName={"supraman"} releaseData={this.state.supramanReleaseData}/>
                    <ReleasesComp artistName={"mousepad"} releaseData={this.state.mousepadReleaseData}/>
                    <ReleasesComp artistName={"whiptrip6"} releaseData={this.state.whiptripReleaseData}/>
                    {/*<ReleasesComp artistName={"Nick Valentine"} releaseData={this.state.nickValentineReleaseData}/>*/}
                    {/*<ReleasesComp artistName={"Goff Mideon"} releaseData={this.state.goffMideonReleaseData}/>*/}
                    {/*<ReleasesComp artistName={"Chase GOD"} releaseData={this.state.chaseGodReleaseData}/>*/}
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        );
    };
}

export default releasesPage;