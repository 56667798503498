import React from "react";
import PageContainer from "../components/PageContainer";
import Footer from "../components/footer";
import HeaderOne from "../components/header-one";
import MenuContextProvider from "../context/menu-context";
import {Col, Container, Row} from "react-bootstrap";

class Errpage extends React.Component {
    render() {

        return (
            <MenuContextProvider>
                <PageContainer PageTitle="Home One">
                    <HeaderOne/>
                    <div className="artistPageImage" style={{backgroundImage: "url(/images/bg/errImage.png)"}}>
                        <Container fluid>
                            <Row>
                                <Col className="text-center"> <span style={{ fontFamily: "fontAwesome",color: "#e62b4a"}}> err: 404  </span></Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <a style={{color: "white"}} href={"/"}>
                                        <button
                                            className="common_btn red_bg"
                                            type="submit"
                                            id="con_submit"
                                        >
                                            <span>Return Home</span>
                                        </button>
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        )
    }
}

export default Errpage;
