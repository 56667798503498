import React, {Fragment} from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, EffectFade, Navigation} from "swiper";

SwiperCore.use([Autoplay, Navigation, EffectFade]);

class ArtistMusicVideoSlider extends React.Component {
    state = {
        videoSliderData: this.props.data.videoSliderData,
        index: 0,
        options: {
            spaceBetween: 25,
            loop: false,
            slidesPerView: 1,
            navigation: {
                nextEl: "#main-slider-next",
                prevEl: "#main-slider-prev"
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: true,
            },
            freeMode: {
                enabled: true,
                sticky: true,
            }

        }
    };

    updateItem(id, itemAttributes) {
        const index = this.state.videoSliderData.findIndex(x => x.key === id);
        if (index === -1) {
            console.log("index error")
        }else{
            this.setState({
                videoSliderData: [
                    ...this.state.videoSliderData.slice(0,index),
                    Object.assign({}, this.state.videoSliderData[index], itemAttributes),
                    ...this.state.videoSliderData.slice(index+1)
                ]
            });
        }
    }

    render() {
        return (
            <Swiper id="videos"
                {...this.state.options}
                >
            {this.state.videoSliderData.map(({ image, title, id, isOpen }, index) => (
                <SwiperSlide key={index}>
                    <Fragment>
                        <section  className="commonSection what_wedo pb-0 border-bottom-0">
                            <Container>
                                <Row>
                                    <Col lg={12} className="text-center">
                                        <h4 className="sub_title">{this.state.videoSliderData[index].subTitle}</h4>
                                        <h2 className="sec_title">{this.state.videoSliderData[index].title}</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className="videoWrap mb-0">
                                            <img src={this.state.videoSliderData[index].image} alt="" />
                                            <div className="play_video">
                                                <a
                                                    className="video_popup"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        console.log(index);
                                                        console.log(this.state.videoSliderData[index].key);
                                                        console.log(isOpen);
                                                        this.setState({index: index})
                                                        this.updateItem(this.state.videoSliderData[index].key, {isOpen: true});
                                                        console.log(index);
                                                        console.log(this.state.videoSliderData[index].key);
                                                        console.log(isOpen);
                                                    }}
                                                    href="#"
                                                >
                                                    <i className="fa fa-play"/>
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Fragment>
                </SwiperSlide>
            ))}
                <div className="swiper-button-prev" id="main-slider-prev"/>
                <div className="swiper-button-next" id="main-slider-next"/>
                <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={this.state.videoSliderData[this.state.index].isOpen}
                    videoId={this.state.videoSliderData[this.state.index].id}
                    onClose={() => this.updateItem(this.state.videoSliderData[this.state.index].key, {isOpen: false})}
                />
            </Swiper>


        );
    }
}

export default ArtistMusicVideoSlider;
