import React, {Fragment} from "react";
import {Col, Container, Row} from "react-bootstrap";


class FeaturedAlbums extends React.Component {
    state = {
        artistReleases: this.props.data.artistReleases,
        artistNameLink: this.props.data.artistNameLink
    };
    render() {
        return (
            <section id="releases"  className="commonSection porfolio">
                <Container>
                    <Row>
                        <Col lg={12} className="text-center">
                            <Fragment>
                                <h2 className="sec_title">Releases</h2>
                            </Fragment>
                        </Col>
                    </Row>
                    <Row id="Grid">
                        <div className="custom">
                            <Row>
                                {this.props.data.artistReleases.map((post, index) => (
                                    <Col lg={4} md={6} sm={12} key={index}>
                                        <a href={post.url} target="_blank">
                                            <div className={`singlefolio`}>
                                                <img src={post.image} alt={post.title} />
                                                <div className="folioHover">
                                                    <h4>
                                                        {post.title}
                                                    </h4>
                                                </div>
                                            </div>
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col lg={12} className="text-center">
                                    <a className="common_btn red_bg" href={"/releases#"+this.state.artistNameLink}>
                                        <span>View More</span>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </section>
        );

    }

}

export default FeaturedAlbums;
