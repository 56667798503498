import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import ServiceDetails from "../components/service-details";
import CallToActionOne from "../components/call-to-action-one";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import Banner from "../components/banner";

class MixAndMaster extends React.Component {
    state = {
        bannerh4: "Pre-Production",
        bannerh2: "",
        bannerImage: "/images/services/banner.png",
        serviceImage: "/images/services/mix.png",
        serviceDescriptionTitle: "Studio Recording",
        serviceDescription: "Half House Studios is the perfect choice for anyone looking for high-quality music mixing and mastering services. Our studio is staffed by experienced and knowledgeable audio engineers who are committed to providing the highest quality results. We use only the latest and most advanced audio equipment to ensure that your mix and master is of the highest quality. Our engineers are also experienced in a variety of musical genres, so you can be sure that your mix and master will sound exactly the way you want it to. We also offer competitive rates and flexible payment plans, so you can get the best value for your money. With Half House Studios, you can be sure that your music will sound the way you envisioned it.",
        serviceName1: "Pre-Production",
        serviceLink1: "/services/preproduction",
        serviceName2: "Music Production & Writing",
        serviceLink2: "/services/productionandwriting",
        serviceName3: "Studio Recording",
        serviceLink3: "/services/studiorecording",
        serviceName4: "Music & Promo Videos",
        serviceLink4: "/services/video",
        serviceName5: "Release Management",
        serviceLink5: "/services/management",
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <Banner data={this.state}/>
                    <ServiceDetails data={this.state}/>
                    <CallToActionOne extraClassName="ready"/>
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        );
    };
}

export default MixAndMaster;