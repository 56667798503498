import MenuContextProvider from "../context/menu-context";
import PageContainer from "../components/PageContainer";
import HeaderOne from "../components/header-one";
import React from "react";
import ArtistImage from "../components/artistImage";
import ArtistMenu from "../components/artistMenu";
import ArtistSummary from "../components/artistSummary";
import FeaturedAlbums from "../components/featuredAlbums";
import Footer from "../components/footer";
import ContactArtist from "../components/contactArtist";
import SocialMediaBanner from "../components/socialMediaBanner";

class NickV extends React.Component {
    state = {
        artist: "Nick Valentine",
        artistRealName: "Nick Henson",
        artistNameLink: "nickValentine",
        artistEmail: "contact@halfhousestudios.com",
        backgroundImage: "/images/bg/nickV.png",
        SummaryImage: "/images/nickV/artistPage.jpg",
        spotifyArtistLink: "https://open.spotify.com/embed/artist/5AlOm16YmmFrxnJz2t5z40?utm_source=generator&theme=0",
        artistSummaryP1: "Nick Valentine has been a musician since a young age, playing both guitar and piano. He then progressed to music production during his early adult life, though these days he mostly focuses on vocal performances. His musical influences stem from a vast range of genres, such as punk, hip hop, psychedelic rock, funk, jazz, R&B, heavy metal, and soul. He is always eager to explore and experiment with new sounds, and is determined to be a part of the music of the future. Nick has been inspired by many renowned artists, including MF DOOM, Daft Punk, The Beatles, Danny Brown, Death Grips, Pink Floyd, Flying Lotus, Gorillaz, JPEGMAFIA, J Dilla, Kendrick Lamar, My Chemical Romance, and many more.",
        artistPageMenu: [
            {
                name: "Releases",
                url: "#releases"
            },
            // {
            //     name: "Videos",
            //     url: "#videos",
            // },
            // {
            //     name: "Merch",
            //     url: "/merch"
            // },
            {
                name: "Contact",
                url: "#contact"
            }
        ],
        artistReleases: [
            {
                title: "Welcome To HalfHouse",
                image: "/images/releases/welcomeToTheHalfHouse.png",
                url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
            },
            {
                title: "Fuck The Rich",
                image: "/images/releases/fuckTheRich.png",
                url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
            },
            {
                title: "Lost In The Night",
                image: "/images/releases/lostInTheNight.png",
                url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
            },
        ],
        index: 0,
        mainSlideOptions: {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: "#main-slider-next",
                prevEl: "#main-slider-prev"
            }
        },
        socialMedia: [
            {
                name: "Instagram",
                url: "https://www.instagram.com/nick__valentinee/?igshid=YmMyMTA2M2Y%3D"

            }
        ]
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <ArtistImage bgImage={this.state.backgroundImage} artist={this.state.artist}/>
                    <ArtistMenu data={this.state}/>
                    <SocialMediaBanner data={this.state.socialMedia} />
                    <ArtistSummary data={this.state}/>
                    {/*<ArtistMediaPlayer data={this.state}/>*/}
                    <FeaturedAlbums data={this.state}/>
                    {/*<ArtistMusicVideoSlider data={this.state}/>*/}
                    <ContactArtist artistEmail={this.state.artistEmail} />
                    <Footer />
                </PageContainer>
            </MenuContextProvider>
        )
    }
}
export default NickV;