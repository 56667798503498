import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

// importing all the pages
import Main from "./pages/index";

import Artists from "./pages/artists";
import Supraman from "./pages/Supraman";
import Mousepad from "./pages/Mousepad";
import NickValentine from "./pages/NickV";
import WhipTrip from "./pages/WhipTrip";
import GoffMideon from "./pages/GoffMideon";

import Service from "./pages/service";
import PreProduction from "./pages/services-preproduction";
import ProductionAndWriting from "./pages/services-productionandwriting";
import StudioRecording from "./pages/services-studiorecording";
import MixAndMaster from "./pages/services-mixandmaster";
import ServicesVideo from "./pages/services-video";
import ServicesManagement from "./pages/services-management";

import Releases from "./pages/releases";

// import SoundPacks from "./pages/soundpacks";
// import SoundpackDetails from "./pages/soundpack-details";
import ContactPage from "./pages/contact";
import Err404 from "./pages/Errpage";
import ComingSoon from "./components/comingSoon";


class MyRouts extends React.Component {

  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main/>} />
            <Route path="/supraman" element={<Supraman/>} />
            <Route path="/mousepad" element={<Mousepad/>} />
            <Route path="/nickvalentine" element={<NickValentine/>} />
            {/*<Route path="/chasegod" element={<ChaseGOD/>} />*/}
            <Route path="/whiptrip6" element={<WhipTrip/>} />
            <Route path="/goffmideon" element={<GoffMideon/>} />
            <Route path="/artists" element={<Artists/>} />
            <Route path="/releases" element={<Releases />} />
            {/*<Route path="/soundpacks" element={<SoundPacks />} />*/}
            {/*<Route path="/soundpacks/:id" element={<SoundpackDetails /> } />*/}
            <Route path="/soundpacks" element={<ComingSoon />} />
            <Route path="/services" element={<Service />} />
            <Route path="/services/preproduction" element={<PreProduction />} />
            <Route path="/services/productionandwriting" element={<ProductionAndWriting />} />
            <Route path="/services/studiorecording" element={<StudioRecording />} />
            <Route path="/services/mixandmaster" element={<MixAndMaster />} />
            <Route path="/services/video" element={<ServicesVideo />} />
            <Route path="/services/management" element={<ServicesManagement />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="*" element={<Err404/>} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default MyRouts;

//TODO:Create scheduling service for studio sessions and tie it into the stripe payment gateway
//TODO:Create API for onboarding/removing artists and allowing uploads/page edits.