import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_n26qqet', 'template_aym77jn', form.current, '6eShF2bNpVf8OGGPq')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
  };
  return (
    <section className="commonSection ContactPage">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="sec_title">get in touch!</h2>
            <h4 className="sec_desc">Your email will be sent to contact@halfhousestudios.com </h4>
            <p className="sec_desc">Half House is proud to work with so many amazing artists. But, since we do work with so many some of our artists are not yet listed under the "artists" tab. <br/>
            If the artist you would like to contact is listed under the artist tab you can contact them directly through their page's contact form.<br/>
            Otherwise, while we are working to onboard all of our artists, we will forward your email to any unlisted Half House Artists on your behalf.</p>
            <p className="sec_desc">If you are inquiring about working with Half House Studios, A specific service we offer, or Have questions. Your email will be routed to the person best suited to help!</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
            <form ref={form} onSubmit={sendEmail} className="contactFrom" id="contactForm" >
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form required"
                    type="text"
                    name="first_name"
                    id="f_name"
                    placeholder="First Name"
                    required="true"
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form"
                    type="text"
                    name="last_name"
                    id="l_name"
                    placeholder="Last Name"
                    required="true"
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form required"
                    type="email"
                    name="sender_email"
                    id="email"
                    placeholder="Email Address"
                    required="true"
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form"
                    type="text"
                    name="sender_phone"
                    id="phone"
                    placeholder="Phone Number (optional)"

                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <input
                      className="input-form"
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required="true"
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <textarea
                  className="input-form required"
                  name="message"
                  id="con_message"
                  placeholder="Write Message"
                  required="true"
                  />
                </div>
              </div>
              <button
                className="common_btn red_bg"
                type="submit"
                id="con_submit"
              >
                <span>Send Email</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
