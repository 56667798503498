import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ServiceCardOne from "./service-card-one";

const ServicePostData = [
    {
        title: "Pre-Production",
        iconName: "icon-noun-promotion-3308526",
        text: "Brainstorm with our Engineers & generate ideas/concepts for the your project. ",
        url: "/services/preproduction"
    },
    {
        title: "Music Production & Writing",
        iconName: "icon-noun-music-4980328",
        text: "Compose the music & write lyrics for each song.",
        url: "/services/productionandwriting"
    },
    {
        title: "Studio Recording",
        iconName: "icon-noun-recording-4362876",
        text: "Record each song until it is perfect in our private in-house studio.",
        url: "/services/studiorecording"
    },
    {
        title: "Mix & Mastering",
        iconName: "icon-noun-ableton-2380263",
        text: "Our Engineers Mix & Master your songs to create a professional-sounding album.",
        url: "/services/mixandmaster"
    },
    {
        title: "Music & Promotional Videos",
        iconName: "icon-noun-video-camera-4749572",
        text: "Work with our Videographers & create videos fans will enjoy for years to come.",
        url: "/services/video"
    },
    {
        title: "Release Management",
        iconName: "icon-noun-data-as-asset-4468380",
        text: "Distribution is a critical. Ensure your album reaches everyone.",
        url: "/services/management"
    }
];
const ServiceOne = () => {
  return (
    <section className="service_section_2 commonSection">
      <Container fluid>
        <Row>
          {ServicePostData.map((post, index) => (
            <Col md={2} key={index}>
              <ServiceCardOne data={post} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServiceOne;
