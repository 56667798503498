import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const artistImage = (props) => {
    return (
        <div className="artistPageImage" style={{backgroundImage: "url("+props.bgImage+")"}}>
            <Container fluid>
                <Row>
                    <Col className="text-center">
                        {props.artist}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default artistImage;
