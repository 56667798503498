import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {MenuContext} from "../context/menu-context";

const LogoImage = {
  light: "/images/HalfHouseLogoWhite.png",
  dark: "/images/HalfHouseLogoBlack.png"
};
const NavLinks = [
  {
    name: "Home",
    url: "/"
  },
  {
    name: "Artists",
    url: "/artists",
    subItems: [
      {
        name: "Supraman",
        url: "/supraman"
      },
      {
        name: "Mousepad",
        url: "/mousepad"
      },
      {
        name: "whiptrip6",
        url: "/whiptrip6"
      },
      {
        name: "Nick Valentine",
        url: "/nickvalentine"
      },
      {
        name: "Goff Mideon",
        url: "/goffmideon"
      },
    ]
  },
  {
    name: "Releases",
    url: "/releases"
  },
  {
    name: "Sound Packs",
    url: "/soundpacks"
  },
  {
    name: "Services",
    url: "/services",
    subItems: [
      {
        name: "Pre-Production",
        url: "/services/preproduction"
      },
      {
        name: "Music Production & Writing",
        url: "/services/productionandwriting"
      },
      {
        name: "Studio Recording",
        url: "/services/studiorecording"
      },
      {
        name: "Mix & Mastering",
        url: "/services/mixandmaster"
      },
      {
        name: "Professional Music Videos",
        url: "/services/video"
      },
      {
        name: "Release Management",
        url: "/services/management"
      }
    ]
  },
  {
    name: "Contact",
    url: "/contact"
  }
];

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false);
  // const { searchStatus, updateSearchStatus } = useContext(SearchContext);
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  // const handleSearchClick = (e) => {
  //   e.preventDefault();
  //   updateSearchStatus(!searchStatus);
  // };
  const handleMenuClick = (e) => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return (
    <header
      className={`header_01 ${
        true === sticky ? "fixedHeader animated flipInX" : null
      }`}
      id="header"
    >
      <Container fluid>
        <Row className="justify-content-between">
          <Col className="col-5" lg={3} md={2} sm={2}>
            <div className="logo">
              <a href="/">
                <img src={LogoImage.light} alt="Half House Logo" />
              </a>
            </div>
          </Col>
          <Col md="auto" className="d-none d-lg-block ">
            <nav className="mainmenu textcenter">
              <ul>
                {NavLinks.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        undefined !== links.subItems
                          ? "menu-item-has-children"
                          : ""
                      }`}
                    >
                      {/*<Link href={links.url}>*/}
                        <a href={links.url}>{links.name}</a>
                      {/*</Link>*/}
                      {undefined !== links.subItems ? (
                        <ul className="sub-menu">
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              {/*<Link href={subLinks.url}>*/}
                                <a href={subLinks.url}>{subLinks.name}</a>
                              {/*</Link>*/}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Col>
          <Col lg={3} md={2} sm={4} className="col-7">
            <div className="navigator text-right">
              {/*<a*/}
              {/*  className="search searchToggler"*/}
              {/*  href="#"*/}
              {/*  onClick={handleSearchClick}*/}
              {/*>*/}
              {/*  <i className="mei-magnifying-glass"/>*/}
              {/*</a>*/}
              <a
                href="#"
                className="menu mobilemenu d-none d-md-none d-lg-none"
              >
                <i className="mei-menu"/>
              </a>
              <a
                id="open-overlay-nav"
                className="menu hamburger"
                onClick={handleMenuClick}
                href="#"
              >
                <i className="mei-menu"/>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderOne;
