import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import {Col, Container, Row} from "react-bootstrap";


class ComingSoon extends React.Component {
    state = {
        bannerImage: "/images/bg/artistBanner.jpg"
    }
    render() {

        return (
            <MenuContextProvider>
                <PageContainer PageTitle="Home One">
                    <HeaderOne/>
                    <div className="artistPageImage" style={{backgroundColor: "black"}}>
                        <Container fluid>
                            <Row>
                                <Col className="text-center"> <span style={{ fontFamily: "fontAwesome",color: "whitesmoke"}}> Coming Soon! </span></Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <a style={{color: "black"}} href={"/"}>
                                        <button
                                            className="common_btn red_bg"
                                            type="submit"
                                            id="con_submit"
                                            style={{color:"black", background: "whitesmoke"}}
                                        >
                                            <span>Return Home</span>
                                        </button>
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        )
    }
}

export default ComingSoon;