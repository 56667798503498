import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import ServiceOne from "../components/service-one";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import Banner from "../components/banner";

class ServicePage extends React.Component {
  state = {
    bannerh4: "services",
    bannerh2: "",
    bannerImage: "/images/bg/servicesBanner.png",
  }
  render() {
    return (
        <MenuContextProvider>
            <PageContainer>
              <HeaderOne/>
              <Banner data={this.state}/>
              <ServiceOne/>
              {/*TODO: create timeline for services*/}
              <Footer/>
            </PageContainer>
        </MenuContextProvider>
    );
  };
}

export default ServicePage;
