import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import Banner from "../components/banner";
import CallToActionOne from "../components/call-to-action-one";
import ArtistCarousel from "../components/imageDescriptionCarousel";
import HeaderOne from "../components/header-one";
import MenuContextProvider from "../context/menu-context";


class artists extends React.Component {
    state = {
        bannerh4: "artists",
        bannerh2: "",
        bannerImage: "/images/bg/artistBanner.jpg",
        carouselOptions: {
            effect: "coverflow",
            spaceBetween: 25,
            loop: true,
            slidesPerView: 5,
            navigation: {
                nextEl: "#main-slider-next",
                prevEl: "#main-slider-prev"
            },
            autoplay: {
              delay: 3000,
              disableOnInteraction: true,
            },
            coverflowEffect: {
                rotate: 5,
                scale: 1,
                stretch: 0,
                depth: 10,
                modifier: 4,
                slideShadows: true,
            },
            freeMode: {
                enabled: true,
                sticky: true,
            }
        },
        artistCards: [
            {
                name: "Supraman",
                socials: [
                    {
                        name: "Instagram",
                        url: "https://www.instagram.com/_supra.man/"

                    },
                    {
                        name: "Spotify",
                        url: "https://open.spotify.com/artist/2Q29TJUDQC1MBN9n7eVSAe?si=KcGtkvlcRDGnP-8P7LCdug&nd=1"

                    }
                ],
                designation: "Artist",
                image: "/images/Supraman/artistPage.jpg",
                url: "/supraman"
            },
            {
                name: "Mousepad",
                socials: [
                    {
                        name: "Instagram",
                        url: "https://www.instagram.com/mousepadmusic/"

                    },{
                        name: "Twitter",
                        url: "https://twitter.com/mousepadmusic/"

                    },
                    {
                        name: "Youtube",
                        url: "https://youtube.com/channel/UCt3zGb9yz-28HyiGtM9yaRg"

                    },
                    {
                        name: "Spotify",
                        url: "https://open.spotify.com/artist/4S5BKypzX8ejWQdb4Wj0YP?si=ZPf5eJ2vQNyKJbwnCBo_gA"

                    },
                    {
                        name: "Apple Music",
                        url: "https://music.apple.com/us/artist/mousepad/1482039968"

                    },
                    {
                        name: "Bandcamp",
                        url: "https://mousepadmusic.bandcamp.com/album/absent-minded"

                    }
                ],
                designation: "Audio Engineer / Artist",
                image: "/images/mousepad/artistPage.jpg",
                url: "/mousepad"
            },
            {
                name: "whiptrip6",
                socials: [
                    {
                        name: "Twitter",
                        url: "https://twitter.com/whiptrip6"

                    },
                    {
                        name: "Instagram",
                        url: "https://www.instagram.com/whiptrip6/"

                    },
                    {
                        name: "SoundCloud",
                        url: "https://soundcloud.com/whiptrip6"

                    }
                ],
                designation: "Audio Engineer / Artist",
                image: "/images/whiptrip6/artistSwipper.jpg",
                url: "/whiptrip6"
            },
            {
                name: "Nick Valentine",
                socials: [
                    {
                        name: "Instagram",
                        url: "https://www.instagram.com/nick__valentinee/?igshid=YmMyMTA2M2Y%3D"

                    }
                ],
                designation: "Artist",
                image: "/images/nickV/artistPage1.jpg",
                url: "/nickvalentine"
            }
            ,
            {
                name: "Goff Mideon",
                socials: [
                    {
                        name: "Youtube",
                        url: "https://www.youtube.com/@GoffMideon"

                    },
                    {
                        name: "TikTok",
                        url: "https://www.tiktok.com/@goff_mideon"

                    },
                    {
                        name: "Instagram",
                        url: "https://www.instagram.com/goffmideon/"

                    },
                    {
                        name: "Twitter",
                        url: "https://twitter.com/goff_mideon"

                    }

                ],
                designation: "Videographer / Artist",
                image: "/images/goffMideon/artistPage.jpg",
                url: "/goffmideon"
            }
        ],
    }
    render() {

        return (
            <MenuContextProvider>
                    <PageContainer PageTitle="Half House Artists">
                        <HeaderOne/>
                        <Banner data={this.state}/>
                        <ArtistCarousel options={this.state.carouselOptions} data={this.state}/>
                        <CallToActionOne extraClassName="ready"/>
                        <Footer/>
                    </PageContainer>
            </MenuContextProvider>
        );
    }
}

export default artists;
