import React from "react";
import PageContainer from "../components/PageContainer";
import CallToActionOne from "../components/call-to-action-one";
import Footer from "../components/footer";
import StudioReleases from "../components/featuredAlbums";
import SoundPacks from "../components/artistSoundPacks";
import ServiceTwo from "../components/service-two";
import AboutTwo from "../components/about-two";
import HeaderOne from "../components/header-one";
import MenuContextProvider from "../context/menu-context";
import SliderOne from "../components/slider-one";
import VideoSection from "../components/videoSection";

class LandingPage extends React.Component {
  state = {
    artistNameLink: "",
    artistReleases: [
      {
        title: "Welcome to half house",
        image: "/images/releases/welcomeToTheHalfHouse.png",
        url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
      },
      {
        title: "Girls!",
        image: "/images/releases/girls.png",
        url: "https://open.spotify.com/album/34yQQ3oVfiI0bsMgKBHOJt?si=T-CIdG33Sbu87rHkuWz_Ug"
      },
      {
        title: "Lost In The Night",
        image: "/images/releases/lostInTheNight.png",
        url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
      },
      {
        title: "F**k The Rich",
        image: "/images/releases/fuckTheRich.png",
        url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
      },
      {
        title: "No Fear",
        image: "/images/releases/noFear.png",
        url: "https://open.spotify.com/track/26qLIxMeCcuLgRl0xmo85x?si=6074dcbc5e804dee"
      },
      {
        title: "Find My Way",
        image: "/images/releases/findMyWay.png",
        url: "https://open.spotify.com/album/1kZLsXNUk9QFmprJnsHa1t?si=BMBcT1AZSEKLWZrmG4AeXQ"
      }
    ],
    SoundPackData: [
      {
        title: "Sound Pack 1",
        categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
        image: "/images/releases/soundPack1.png",
        url: "/soundpacks"
      },
      {
        title: "Sound Pack 2",
        categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
        image: "/images/releases/soundPack2.png",
        url: "/soundpacks"
      },
      {
        title: "Sound Pack 3",
        categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
        image: "/images/releases/soundPack3.png",
        url: "/soundpacks"
      },
      {
        title: "Sound Pack 4",
        categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
        image: "/images/releases/soundPack4.png",
        url: "/soundpacks"
      },
      {
        title: "Sound Pack 5",
        categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
        image: "/images/releases/soundPack5.png",
        url: "/soundpacks"
      },
      {
        title: "Sound Pack 6",
        categories: ["Hip-Hop, ", "Lo-Fi, ", "Pop"],
        image: "/images/releases/soundPack6.png",
        url: "/soundpacks"
      }
    ],
    VideoOneData: {
      sectionContent: {
        title: "Find My Way",
        subTitle: "Official Music Video",
        text:
            ""
      },
      video: {
        title: "",
        ID: "l1K7yeOyruQ",
        image: "/video/gifs/findMyWayCut.gif"
      }
    },
    VideoTwoData: {
      sectionContent: {
        title: "Pod Nem: Deathcon 3?! What Kanye??",
        subTitle: "Pod nem | SZN 2 EP 2",
        text: ""
      },
      video: {
        title: "",
        ID: "YLS8vnHLHWg",
        image: "/video/gifs/pod3.gif"
      }
    }
  }
  render() {
    return (
        <MenuContextProvider>
          <PageContainer >
            <HeaderOne/>
            <SliderOne/>
            <ServiceTwo/>
            <AboutTwo/>
            <VideoSection data={this.state.VideoOneData}/>
            <StudioReleases data={this.state}/>
            <VideoSection data={this.state.VideoTwoData}/>
            <SoundPacks data={this.state}/>
            <CallToActionOne />
            <Footer/>
          </PageContainer>
        </MenuContextProvider>
    );
  }
}

export default LandingPage;
