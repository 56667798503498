import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const AboutTwo = () => {
  const Data = {
    sectionContent: {
      title: "Small spaces, big ideas.",
      subTitle: "Half House Studios:",
      text:
          "Half House Studios is a music label dedicated to providing a supportive and creative environment for up-and-coming artists in Champaign-Urbana, Illinois. " +
          "We aim to foster a community of musicians where creativity and collaboration are encouraged. " +
          "We believe that music has the power to bring people together and inspire positive change. " +
          "\n\nHalf House studios produces and promotes all genres of music. " +
          "The mission is to provide a platform for our artists in order to showcase their talents and reach a wider audience."
    },
    button: {
      label: "Learn More",
      url: "#"
    }
  };
  return (
    <section className="commonSection ab_agency">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} className="PR_79">
            <h4 className="sub_title">{Data.sectionContent.subTitle}</h4>
            <h2 className="sec_title MB_45">{Data.sectionContent.title}</h2>
            <p className="sec_desc">{Data.sectionContent.text}</p>
          </Col>
          <Col lg={6} md={6} sm={12}>
              <div className={`ab_img1`}>
                <img src="/images/landing/about.png" alt="" />
                <br/>
                <br/>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
