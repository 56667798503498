import MenuContextProvider from "../context/menu-context";
import PageContainer from "../components/PageContainer";
import HeaderOne from "../components/header-one";
import React from "react";
import ArtistImage from "../components/artistImage";
import ArtistMenu from "../components/artistMenu";
import ArtistSummary from "../components/artistSummary"
// import ArtistMediaPlayer from "../components/artistMediaPlayer";
import FeaturedAlbums from "../components/featuredAlbums";
// import ArtistMusicVideoSlider from "../components/artistMusicVideoSlider";
import ContactArtist from "../components/contactArtist";
import Footer from "../components/footer";
import SocialMediaBanner from "../components/socialMediaBanner";

class GoffMideon extends React.Component {
    state = {
        artist: "Goff Mideon",
        artistRealName: "Andrew Cerezo",
        artistNameLink: "goffMideon",
        artistEmail: "goffmideon@halfhousestudios.com",
        backgroundImage: "/images/bg/goffMideon.png",
        SummaryImage: "/images/goffMideon/summaryImage.jpg",
        spotifyArtistLink: "https://open.spotify.com/embed/artist/13v0fOzWzB0XdbyzczOgjG?utm_source=generator&theme=0",
        artistSummaryP1: "Goff Mideon, formerly known as A I R I, is renowned for his contribution to Half House's discography. He holds a degree from the University of Wisconsin-Milwaukee, where he studied film and acting. ",
        artistSummaryP2: "After seeing the potential of his hometown and shooting some music videos while visiting, he moved into Half House to further his musical career, as well as take up the role of lead filmmaker. " +
            "As such, he is responsible for all video production, ranging from podcasts and interviews to skits and music videos for Half House Studios.",
        artistPageMenu: [
            {
                name: "Releases",
                url: "#releases"
            },
            {
                name: "Videos",
                url: "#videos",
            },
            // {
            //     name: "Merch",
            //     url: "/merch"
            // },
            {
                name: "Contact",
                url: "#contact"
            }
        ],
        artistReleases: [
            {
                title: "Welcome To HalfHouse",
                image: "/images/releases/welcomeToTheHalfHouse.png",
                url: "https://open.spotify.com/album/4u4VnzdDPXlSsJ63JJiY3o?si=T_5vuEVmSama2pICeG7D5w"
            },
        ],
        index: 0,
        mainSlideOptions: {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: "#main-slider-next",
                prevEl: "#main-slider-prev"
            }
        },
        videoSliderData: [
            {
                key: 1,
                image: "/images/Supraman/findMyWayCut.gif",
                title: "Find My Way",
                subTitle: "official music video",
                id: "l1K7yeOyruQ",
                isOpen: false
            },
            {
                key: 2,
                image: "/images/Supraman/findMyWayCut.gif",
                title: "Vapor Dreams Feat. Supraman",
                subTitle: "official music video",
                id: "JrexK0LbDic",
                isOpen: false
            },
            {
                key: 3,
                image: "/images/Supraman/findMyWayCut.gif",
                title: "Pod Nem: Deathcon3?! What Kanye??",
                subTitle: "Podcast",
                id: "YLS8vnHLHWg",
                isOpen: false
            }
        ],
        socialMedia: [
            {
                name: "Youtube",
                url: "https://www.youtube.com/@GoffMideon"

            },
            {
                name: "TikTok",
                url: "https://www.tiktok.com/@goff_mideon"

            },
            {
                name: "Instagram",
                url: "https://www.instagram.com/goffmideon/"

            },
            {
                name: "Twitter",
                url: "https://twitter.com/goff_mideon"

            }

        ]
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <ArtistImage bgImage={this.state.backgroundImage} artist={this.state.artist}/>
                    <ArtistMenu data={this.state}/>
                    <SocialMediaBanner data={this.state.socialMedia} />
                    <ArtistSummary data={this.state}/>
                    {/*<ArtistMediaPlayer data={this.state}/>*/}
                    <FeaturedAlbums data={this.state}/>
                    {/*<ArtistMusicVideoSlider data={this.state}/>*/}
                    <ContactArtist artistEmail={this.state.artistEmail} />
                    <Footer />
                </PageContainer>
            </MenuContextProvider>
        )
    }
}

export default GoffMideon;