import React, {Fragment, useState} from "react";
import ModalVideo from "react-modal-video";
import SectionTitle from "./section-title";
import "react-modal-video/css/modal-video.min.css";
import {Col, Container, Row} from "react-bootstrap";

const VideoSection = (props) => {
    const [isOpen, setOpen] = useState(false);
    const { sectionContent, video } = props.data;
    return (
        <Fragment >
            <section id="videos" className="commonSection">
                <Container>
                    <Row>
                        <Col lg={12} className="text-center">
                            <SectionTitle data={sectionContent} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="videoWrap mb-0">
                                <img src={video.image} alt="" />
                                <div className="play_video">
                                    <a
                                        className="video_popup"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpen(true);
                                        }}
                                        href="#"
                                    >
                                        <i className="fa fa-play"/>
                                    </a>
                                    <h2>{video.title}</h2>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId={video.ID}
                onClose={() => setOpen(false)}
            />
        </Fragment>
    );
};

export default VideoSection;
