import React from "react";
import {Col, Container, Row} from "react-bootstrap";


class artistMediaPlayer extends React.Component {
    render() {
        return (
            <div>
                <Container >
                             <Row>
                                 <Col lg={{span: 12}}>
                                        <iframe style={{boarderRadius: 12 + "px"}}
                                         src={this.props.data.spotifyArtistLink}
                                         width="100%" height="400" frameBorder="0" allowFullScreen=""
                                         allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                         loading="lazy"/>
                                 </Col>
                             </Row>
                </Container>
            </div>
        );
    }
}

export default artistMediaPlayer;