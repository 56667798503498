import React from "react";
import {Col, Container, Row} from "react-bootstrap";

class SocialMediaBanner extends React.Component {
    render() {
        return (
            <div className="socialMediaBanner-container justify-content-lg-center">
                <Container>
                    <Row>
                        <Col>
                            <nav className="socialMediaBanner text-center">
                                <ul>
                                    {this.props.data.map((social, index) => {
                                        return (
                                            <li key={index}>
                                                <a href={social.url}>{social.name}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default SocialMediaBanner;