import React, {Fragment} from "react";
import {Col, Container, Row} from "react-bootstrap";


class releasesComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            artistName: props.artistName
        };
    }
    render() {
        return (
            <section id={this.state.artistName}  className="commonSection porfolio">
                <Container>
                    <Row>
                        <Col lg={12} className="text-center ">
                            <Fragment>
                                <h2 className="sec_title">{this.state.artistName}</h2>
                            </Fragment>
                        </Col>
                    </Row>
                    <Row id="Grid">
                        <div className="custom">
                            <Row>
                                {this.props.releaseData.map((post, index) => (
                                    <Col lg={4} md={6} sm={12} key={index}>
                                        <a href={post.url} target="_blank">
                                            <div className={`singlefolio`}>
                                                <img src={post.image} alt={post.title} />
                                                <div className="folioHover">
                                                    <h4>
                                                        {post.title}
                                                    </h4>
                                                </div>
                                            </div>
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Row>
                </Container>
            </section>
        );

    }

}

export default releasesComp;