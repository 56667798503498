import React, {Fragment, useContext, useRef} from "react";
import {MenuContext} from "../context/menu-context";

const LogoImage = {
  light: "/images/HalfHouseLogoWhite.png",
  dark: "/images/HalfHouseLogoBlack.png"
};

const NavLinks = [
  {
    name: "Home",
    url: "/"
  },
  {
    name: "Artists",
    url: "/artists",
    subItems: [
      {
        name: "Supraman",
        url: "/supraman"
      },
      {
        name: "Mousepad",
        url: "/mousepad"
      },
      {
        name: "whiptrip6",
        url: "/whiptrip6"
      },
      {
        name: "Nick Valentine",
        url: "/nickvalentine"
      },
      {
        name: "Goff Mideon",
        url: "/goffmideon"
      },
    ]
  },
  {
    name: "Releases",
    url: "/releases"
  },
  {
    name: "Sound Packs",
    url: "/soundpacks"
  },
  {
    name: "Services",
    url: "/services",
    subItems: [
      {
        name: "Pre-Production",
        url: "/services/preproduction"
      },
      {
        name: "Music Production & Writing",
        url: "/services/productionandwriting"
      },
      {
        name: "Studio Recording",
        url: "/services/studiorecording"
      },
      {
        name: "Mix & Mastering",
        url: "/services/mixandmaster"
      },
      {
        name: "Professional Music Videos",
        url: "/services/video"
      },
      {
        name: "Release Management",
        url: "/services/management"
      }
    ]
  },
  {
    name: "Contact",
    url: "/contact"
  }
];

const PopupMenu = () => {
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const menuEl = useRef(null);
  const handleMenuClick = (e) => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };
  return (
    <div className="show-overlay-nav">
      <div className="popup popup__menu">
        <a
          href=""
          id="close-popup"
          onClick={handleMenuClick}
          className="close-popup"
        ></a>
        <div className="container mobileContainer">
          <div className="row">
            <div className="col-lg-12 text-left">
              <div className="logo2">
                <a href="/">
                  <a>
                    <img src={LogoImage.light} alt="" />
                  </a>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="popup-inner">
                <nav
                  className="popup-menu dl-menu__wrap text-center"
                  ref={menuEl}
                >
                  <ul>
                    {NavLinks.map((links, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            undefined !== links.subItems
                              ? "menu-item-has-children"
                              : ""
                          }`}
                        >
                          <a href={links.url}>
                            <a>{links.name}</a>
                          </a>
                          {undefined !== links.subItems ? (
                            <Fragment>
                              <button
                                onClick={(e) => {
                                  menuEl.current
                                    .querySelectorAll(".sub-menu")
                                    .forEach((item) => {
                                      item.classList.remove("show");
                                    });

                                  let clickedItem = e.currentTarget.parentNode;
                                  clickedItem
                                    .querySelector(".sub-menu")
                                    .classList.toggle("show");
                                }}
                              >
                                <i className="fa fa-angle-down"></i>
                              </button>
                              <ul className="sub-menu">
                                {links.subItems.map((subLinks, index) => (
                                  <li key={index}>
                                    <a href={subLinks.url}>
                                      <a>{subLinks.name}</a>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </Fragment>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-xs-12 text-center text-md-left">
              <ul className="footer__contacts">
                {/*<li>Phone: 111 111 1111</li>*/}
                <li>Email: contact@HalfHouseStudios.com</li>
                <li>
                  Address: 407 1/2 st Champagne, Illinois, United States of America
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="popUp_social text-center text-md-right">
                <ul>
                  <li>
                    <a href="https://twitter.com/halfhousestu" target="_blank">
                      <i className="fa fa-twitter"/>Twitter
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/halfhousestu/" target="_blank">
                      <i className="fa fa-instagram"/>Instagram
                    </a>

                  </li>
                  <li>
                    <a href="https://www.twitch.tv/halfhousestudios" target="_blank">
                      <i className="fa fa-twitch"/>Twitch
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCLcR_JZRkJqr5f6vvFpOfKQ" target="_blank">
                      <i className="fa fa-youtube-play"/>Youtube
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMenu;
