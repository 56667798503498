import React from "react";

class contactPageBanner extends React.Component {
    render() {
        return (
            <section className="pageBanner" style={{backgroundImage: "url("+this.props.bgImage+")"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner_content text-center">
                                <h4>
                                    contact Half House
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default contactPageBanner;