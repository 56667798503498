import React from "react";

class banner extends React.Component {
    state = {
        bannerImage: this.props.data.bannerImage,
        bannerh4: this.props.data.bannerh4,
        bannerh2: this.props.data.bannerh2
    }

    render() {

        return (
            <section  style={{background: "url("+this.state.bannerImage+") no-repeat center center / cover"}} className="pageBanner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div  className="banner_content text-center">
                                {/*<h4>*/}
                                {/*    <a href="/">home</a> - {name}*/}
                                {/*</h4>*/}
                                <h4>
                                    {this.state.bannerh4}
                                </h4>
                                <h2>{this.state.bannerh2}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default banner;