import React from 'react';
import "typeface-oswald";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/icons.css";
import "./assets/css/fontello.css";
import "./assets/css/preset.css";
import "./assets/css/theme.css";
import "./assets/css/responsive.css";

// importing MyRouts where we located all of our theme
import MyRouts from './routes'

function App() {
  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default App;