import React, {Fragment} from "react";
import {Col, Container, Row} from "react-bootstrap";

class ServiceDetails extends React.Component {
  state ={
    serviceImage: this.props.data.serviceImage,
    serviceDescriptionTitle: this.props.data.serviceDescriptionTitle,
    service2Description: this.props.data.service2Description,
    service2Image: this.props.data.service2Image,
    service2DescriptionTitle: this.props.data.service2DescriptionTitle,
    serviceDescription: this.props.data.serviceDescription,
    serviceName1: this.props.data.serviceName1,
    serviceLink1: this.props.data.serviceLink1,
    serviceName2: this.props.data.serviceName2,
    serviceLink2: this.props.data.serviceLink2,
    serviceName3: this.props.data.serviceName3,
    serviceLink3: this.props.data.serviceLink3,
    serviceName4: this.props.data.serviceName4,
    serviceLink4: this.props.data.serviceLink4,
    serviceName5: this.props.data.serviceName5,
    serviceLink5: this.props.data.serviceLink5,
  }
  render() {
    return (
        <section className="commonSection service_detail">
          <Container>
            <Row>
              <Col lg={8} md={12}>
                <div className="serviceArea">
                  <img src={this.state.serviceImage} alt=""/>
                  <h2>{this.state.serviceDescriptionTitle}</h2>
                  <p>
                    {this.state.serviceDescription}
                  </p>
                </div>
              </Col>
              <Col lg={4} md={12} className="sidebar">
                <Fragment>
                  <aside className="widget categories">
                    <h3 className="widget_title">Services</h3>
                    <div className="meipaly_categorie_widget">
                      <ul>
                        <li>
                          <a href={this.state.serviceLink1}>{this.state.serviceName1}</a>
                        </li>
                        <li>
                          <a href={this.state.serviceLink2}>{this.state.serviceName2}</a>
                        </li>
                        <li>
                          <a href={this.state.serviceLink3}>{this.state.serviceName3}</a>
                        </li>
                        <li>
                          <a href={this.state.serviceLink4}>{this.state.serviceName4}</a>
                        </li>
                        <li>
                          <a href={this.state.serviceLink5}>{this.state.serviceName5}</a>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </Fragment>
              </Col>
              <Col lg={8} md={12}>
                <div className="serviceArea">
                  <img src={this.state.service2Image} alt=""/>
                  <h2 className="text-right">{this.state.service2DescriptionTitle}</h2>
                  <p>
                    {this.state.service2Description}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
    );
  };
}

export default ServiceDetails;
