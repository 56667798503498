import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import ServiceDetails from "../components/service-details";
import CallToActionOne from "../components/call-to-action-one";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import Banner from "../components/banner";

class ServicesManagement extends React.Component {
    state = {
        bannerh4: "Pre-Production",
        bannerh2: "",
        bannerImage: "/images/services/banner.png",
        serviceImage: "/images/services/management.png",
        serviceDescriptionTitle: "Release Management",
        serviceDescription: "Half House Studios is the perfect choice for music release management and marketing. We provide a comprehensive suite of services to help you get your music out to the world. Our experienced professionals have the expertise to help you create and execute a successful release strategy. We understand the nuances of the music industry and will work with you to ensure your music reaches its full potential. Our team is committed to providing the highest quality of service to ensure the success of your music release. We will work with you to ensure that your music is heard by the right people and that it reaches its maximum potential. With Half House Studios, you can trust that your music release will be managed and marketed with the utmost care and attention.",
        serviceName1: "Pre-Production",
        serviceLink1: "/services/preproduction",
        serviceName2: "Music Production & Writing",
        serviceLink2: "/services/productionandwriting",
        serviceName3: "Studio Recording",
        serviceLink3: "/services/studiorecording",
        serviceName4: "Mix & Mastering",
        serviceLink4: "/services/mixandmaster",
        serviceName5: "Music & Promo Videos",
        serviceLink5: "/services/video",
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <Banner data={this.state}/>
                    <ServiceDetails data={this.state}/>
                    <CallToActionOne extraClassName="ready"/>
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        );
    };
}

export default ServicesManagement;