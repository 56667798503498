import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import ServiceDetails from "../components/service-details";
import CallToActionOne from "../components/call-to-action-one";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import Banner from "../components/banner";

class PreProduction extends React.Component {
    state = {
        bannerh4: "Pre-Production",
        bannerh2: "",
        bannerImage: "/images/services/banner.png",
        serviceImage: "/images/services/preproduction.png",
        serviceDescriptionTitle: "Pre-Production",
        serviceDescription: "Are you looking for a reliable, experienced music pre-production expert? Look no further! Half House\n" +
            "                    Studios offers extensive experience and knowledge of the industry, we are the perfect choice for\n" +
            "                    your music pre-production needs.\n" +
            "                    We specialize in helping musicians create the most professional, polished sound possible for their\n" +
            "                    recordings and that starts with creating an amazing foundation for your album to be built on. Our\n" +
            "                    Audio Engineers have a keen ear for detail and can easily identify any potential issues with your\n" +
            "                    music, making sure your tracks sound perfect before they’re released.\n" +
            "                    We are also flexible and reliable, and we're committed to delivering the best results possible. Half\n" +
            "                    House understand the importance of pre-production and take pride in our work, so you can be sure\n" +
            "                    that we will do whatever it takes to make sure your music is set up to sound exactly how you want it\n" +
            "                    to.\n" +
            "                    If you're looking for a music pre-production expert who can help you create the perfect sound, then\n" +
            "                    Half House is the right choice for you. Let's get to work and make your music sound amazing!",
        serviceName1: "Music Production & Writing",
        serviceLink1: "/services/productionandwriting",
        serviceName2: "Studio Recording",
        serviceLink2: "/services/studiorecording",
        serviceName3: "Mix & Mastering",
        serviceLink3: "/services/mixandmaster",
        serviceName4: "Music & Promo Videos",
        serviceLink4: "/services/video",
        serviceName5: "Release Management",
        serviceLink5: "/services/management",
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <Banner data={this.state}/>
                    <ServiceDetails data={this.state}/>
                    <CallToActionOne extraClassName="ready"/>
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        );
    };
}

export default PreProduction;