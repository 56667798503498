import React from "react";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
import PageBanner from "../components/contactPageBanner";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import PageContainer from "../components/PageContainer";
import ContactSocialMediaMenu from "../components/contactSocialMediaMenu";

class ContactPage extends React.Component {
    state = {
        contactPageBanner: "/images/bg/artistBanner.jpg",
        artistPageMenu: [
            {
                name: "Twitter",
                url: "https://twitter.com/halfhousestu"
            },
            {
                name: "Instagram",
                url: "https://www.instagram.com/halfhousestu/"
            },
            {
                name: "Twitch",
                url: "https://www.twitch.tv/halfhousestudios"
            },
            {
                name: "Youtube",
                url: "https://www.youtube.com/channel/UCLcR_JZRkJqr5f6vvFpOfKQ"
            }
        ],
    }
  render() {
    return (
        <MenuContextProvider>
            <PageContainer>
                <HeaderOne/>
                <PageBanner bgImage={this.state.contactPageBanner} />
                <ContactSocialMediaMenu data={this.state}/>
                <ContactForm/>
                {/*TODO: Add a FAQ section*/}
                <Footer/>
            </PageContainer>
        </MenuContextProvider>
    );
  }
}

export default ContactPage;
