import React from "react";
import {Col, Container, Row} from "react-bootstrap";


class ArtistSummary extends React.Component {

    render() {
        return (
            <Container>
                <Row>
                    <Col md={{offset: 0}} >
                        <h4 className="border-bottom">{this.props.data.artist} AKA {this.props.data.artistRealName}</h4>
                        <p className="text-center" style={{fontSize: "20px"}}>{this.props.data.artistSummaryP1}</p>
                        <p className="text-center" style={{fontSize: "20px"}}>{this.props.data.artistSummaryP2}</p>
                    </Col>
                    <Col md={{ span: 6}} className="ArtistSummaryImage">
                        <img src={this.props.data.SummaryImage} alt="Artist Summary Image"/>
                    </Col>
                </Row>
            </Container>
        );

    }

}

export default ArtistSummary;