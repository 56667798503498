import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ServiceCardTwo from "./service-card-two";

const ServiceTwo = () => {
  const  sectionContent = {
    title: "Half the house \n Never half the quality",
    text: "Whether you're just getting started on a project or are about to release the next Billboard #1 album. " +
        "\nWe have exactly what you're looking for."
  };
  const posts = [
    {
      title: "Production & Writing",
      iconName: "icon-noun-music-4980328",
      text: "Compose the music for each of the selected songs ",
      url: "/services"
    },
    {
      title: "Studio Recording",
      iconName: "icon-noun-recording-4362876",
      text: "Record each song until it is perfect in our private in-house studio.",
      url: "/services"
    },
    {
      title: "Mix & Mastering",
      iconName: "icon-noun-ableton-2380263",
      text: "Our Engineers Mix & Master your songs to create a professional-sounding album.",
      url: "/services"
    },
    {
      title: "Professional Music Videos",
      iconName: "icon-noun-video-camera-4749572",
      text: "Our Videographers can create videos your fans will enjoy for years to come.",
      url: "/services"
    },
    {
      title: "Release Management",
      iconName: "icon-noun-data-as-asset-4468380",
      text: "Distribution is a critical. Ensure your album reaches everyone.",
      url: "/services"
    }
  ]
  return (
    <section className="service_section commonSection">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h4 className="sub_title red_color">{sectionContent.subTitle}</h4>
            <h2 className="sec_title white">{sectionContent.title}</h2>
            <p className="sec_desc color_aaa">{sectionContent.text}</p>
          </Col>
        </Row>
        <Row className="custom_column">
          {posts.map((data, index) => (
            <Col key={index} lg={3} md={6} sm={12}>
              <ServiceCardTwo data={data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServiceTwo;
