import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import ServiceDetails from "../components/service-details";
import CallToActionOne from "../components/call-to-action-one";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import Banner from "../components/banner";

class PreProduction extends React.Component {
    state = {
        bannerh4: "Music Production & Writing",
        bannerh2: "",
        bannerImage: "/images/services/banner.png",
        serviceImage: "/images/services/production.png",
        serviceDescriptionTitle: "Production",
        serviceDescription: "Half House Studios is the perfect choice for anyone looking for top-notch music production. Our team of experienced producers, engineers, and musicians are passionate about creating the perfect sound for your project. We use the latest equipment and techniques, ensuring that your musical vision is realized the way you intended. We also provide a variety of services, including custom music production, mixing, and mastering, making us the perfect one-stop shop for all your music production needs. With our experienced team and state-of-the-art equipment, we guarantee that you will get a professional, high-quality product that will exceed your expectations.",
        service2Description: "Half House Studios is a dynamic and creative music writing studio that strives to elevate the musical art form. Our team of experienced music professionals have an expertise in songwriting, production, engineering, and more, and are committed to delivering high-quality results for our clients. We are passionate about crafting original, innovative music and providing a collaborative environment for our artists to develop their skills and reach their fullest potential. We understand that music is an art form, and we strive to provide our clients with a truly unique and memorable experience. Our team has a deep understanding of the music industry and is here to ensure that your music is crafted to perfection. We are committed to delivering results that exceed expectations, and we guarantee that you will be satisfied with our services.",
        service2Image: "/images/services/writing.png",
        service2DescriptionTitle: "Writing",
        serviceName1: "Pre-Production",
        serviceLink1: "/services/preproduction",
        serviceName2: "Studio Recording",
        serviceLink2: "/services/studiorecording",
        serviceName3: "Mix & Mastering",
        serviceLink3: "/services/mixandmaster",
        serviceName4: "Music & Promo Videos",
        serviceLink4: "/services/video",
        serviceName5: "Release Management",
        serviceLink5: "/services/management",
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <Banner data={this.state}/>
                    <ServiceDetails data={this.state}/>
                    <CallToActionOne extraClassName="ready"/>
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        );
    };
}

export default PreProduction;