import MenuContextProvider from "../context/menu-context";
import PageContainer from "../components/PageContainer";
import HeaderOne from "../components/header-one";
import React from "react";
import ArtistImage from "../components/artistImage";
import ArtistMenu from "../components/artistMenu";
import ArtistSummary from "../components/artistSummary"
import ArtistMediaPlayer from "../components/artistMediaPlayer";
import FeaturedAlbums from "../components/featuredAlbums";
import ArtistMusicVideoSlider from "../components/artistMusicVideoSlider";
import Footer from "../components/footer";
import ContactArtist from "../components/contactArtist";
import SocialMediaBanner from "../components/socialMediaBanner";

class Supraman extends React.Component {
    state = {
        artist: "Supraman",
        artistRealName: "Alan Simpson",
        artistNameLink: "supraman",
        backgroundImage: "/images/bg/supraman.png",
        SummaryImage: "/images/Supraman/supraSummary.jpg",
        artistEmail: "supraman@halfhousestudios.com",
        spotifyArtistLink: "https://open.spotify.com/embed/artist/2Q29TJUDQC1MBN9n7eVSAe?utm_source=generator&theme=0",
        artistSummaryP1: "Born Alan Franklin Simpson, Supraman is a musician and member of Half House from Urbana, IL. " +
            "Involved in music and production since 2007, Supra met whiptrip6 in 2012 and began recording with Half House Studios in 2019.",
        artistSummaryP2: "Supraman's debut album \"Reboot\" was released August 23rd 2021, which saw collaborations with whiptrip6, Mousepad, Herm Everlasting, and relaunched his music career.",
        artistPageMenu: [
            {
                name: "Releases",
                url: "#releases"
            },
            {
                name: "Videos",
                url: "#videos",
            },
            // {
            //     name: "Merch",
            //     url: "/merch"
            // },
            {
                name: "Contact",
                url: "#contact"
            }
        ],
        artistReleases: [
            {
                title: "The Show Goes On",
                image: "/images/releases/TheShowGoesOn.png",
                url: "https://open.spotify.com/album/3VpsF0YvM2XG8kgWxvSTfZ?si=jBYuvyWyS1Sbp28kDXoD7w"
            },
            {
                title: "All Love",
                image: "/images/releases/allLove.png",
                url: "https://open.spotify.com/album/04N2T7dclHeWZYybO9tAsV?si=Zmi33Cl5ShGdR56PO2PsVA"
            },
            {
                title: "Money,Paper",
                image: "/images/releases/moneyPaper.png",
                url: "https://open.spotify.com/album/5SeCJK756MKVHYRRB7Eo6T?si=MFG5oj3uRIyqy91LHmEZxw"
            },
            {
                title: "Girls!",
                image: "/images/releases/girls.png",
                url: "https://open.spotify.com/album/34yQQ3oVfiI0bsMgKBHOJt?si=9afPthb2THaFOvlcB6lNwQ"
            },
            {
                title: "What Can I Say",
                image: "/images/releases/whatCanISay.png",
                url: "https://open.spotify.com/album/0HmoqHZfGfvqqtBiMFcJfh?si=CDMcp1hfRquzxANwkt-1rQ"
            },
            {
                title: "Reboot",
                image: "/images/releases/reboot.png",
                url: "https://open.spotify.com/album/0IplrXRuJrmt9YyG7ArcjV?si=dyzBFucpS7qtGcQKMDT-SA"
            }
        ],
        index: 0,
        mainSlideOptions: {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: "#main-slider-next",
                prevEl: "#main-slider-prev"
            }
        },
        videoSliderData: [
            {
                key: 0,
                image: "/video/gifs/findMyWayCut.gif",
                title: "Find My Way",
                subTitle: "official music video",
                id: "l1K7yeOyruQ",
                isOpen: false
            },
            {
                key: 1,
                image: "/video/gifs/vaporDreams.gif",
                title: "Vapor Dreams Feat. Supraman",
                subTitle: "official music video",
                id: "JrexK0LbDic",
                isOpen: false
            },
            {
                key: 2,
                image: "/video/gifs/pod3.gif",
                title: "Pod Nem: Deathcon3?! What Kanye??",
                subTitle: "Pod Nem | S2 EP3",
                id: "YLS8vnHLHWg",
                isOpen: false
            }
        ],
        socialMedia: [
            {

                name: "Instagram",
                url: "https://www.instagram.com/_supra.man/"

            },
            {

                name: "Spotify",
                url: "https://open.spotify.com/artist/2Q29TJUDQC1MBN9n7eVSAe?si=KcGtkvlcRDGnP-8P7LCdug&nd=1"

            }
        ]
    }
    render() {
        return (
            <MenuContextProvider>
                    <PageContainer >
                        <HeaderOne/>
                        <ArtistImage bgImage={this.state.backgroundImage} artist={this.state.artist}/>
                        <ArtistMenu data={this.state}/>
                        <SocialMediaBanner data={this.state.socialMedia} />
                        <ArtistSummary data={this.state}/>
                        <ArtistMediaPlayer data={this.state}/>
                        <FeaturedAlbums data={this.state}/>
                        <ArtistMusicVideoSlider data={this.state}/>
                        <ContactArtist artistEmail={this.state.artistEmail} />
                        <Footer />
                    </PageContainer>
            </MenuContextProvider>
        )
    }
}

export default Supraman;