import React from "react";

const ServiceCardOne = ({ data }) => {
  const { title, text, iconName, url } = data;
  return (

    <div className="icon_box_2 text-center" style={{background: "url('/images/shadow.png') no-repeat center center / cover;"}}>
      <h3>{title}</h3>
      <p>{text}</p>
        <a href={url}>
      <div className="iconWrap">
        <i className={iconName}></i>
      </div>
        <p style={{borderColor: "gray", borderStyle: "solid", borderWidth: "thin", padding: "10px"}}>discover more</p>
        </a>
    </div>

  );
};

export default ServiceCardOne;
