import React, {Fragment} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, {EffectCoverflow, Navigation, Pagination} from "swiper";

SwiperCore.use([Pagination,Navigation,EffectCoverflow]);

class imageDescriptionCarousel extends React.Component {
    render() {


        return (
            <section className="commonSection team">
                <Container>
                    <Row>
                        <Col lg={12} className="text-center">
                            <Fragment>
                                <h2 className="sec_title">Our Half House Artists</h2>
                            </Fragment>
                        </Col>
                    </Row>
                </Container>
                <Swiper className="team_slider" {...this.props.options}>
                    {this.props.data.artistCards.map(({name, socials, designation, image,url}, index) => (
                        <SwiperSlide key={index}>
                            <a href={url}>
                                <div className="singleTM">
                                    <div className="tm_img">
                                       <img src={image} alt={name} />
                                        <div className="tm_overlay">
                                            <div className="team_social">
                                                {socials.map(({ name, url }, index) => (
                                                    <a key={index} href={url}>
                                                        <span>{name}</span>
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <a href={url}><div className="detail_TM">
                                        <h5>{name}</h5>
                                        <h6>{designation}</h6>
                                    </div></a>
                                </div>
                            </a>
                        </SwiperSlide>
                    ))}{""}
                    <div className="swiper-button-prev" id="main-slider-prev"/>
                    <div className="swiper-button-next" id="main-slider-next"/>
                </Swiper>
            </section>
        );
    }
}

export default imageDescriptionCarousel;
