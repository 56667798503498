import React, {Fragment, useContext, useEffect, useState} from "react";
import {MenuContext} from "../context/menu-context";
import PopupMenu from "./popup-menu";
import {Link as ScrollLink} from "react-scroll";

const PageContainer = ({ PageTitle, children }) => {
    // const { searchStatus } = useContext(SearchContext);
    const { menuStatus } = useContext(MenuContext);
    const [scrollTop, setScrollTop] = useState(false);

    const handleScrollTop = () => {
        if (window.scrollY > 70) {
            setScrollTop(true);
        } else if (window.scrollY < 70) {
            setScrollTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScrollTop);
        return () => {
            window.removeEventListener("scroll", handleScrollTop);
        };
    }, [scrollTop]);

    return (
        <Fragment>
            <div id="wrapper">{children}</div>
            {/*{true === searchStatus ? <SearchPopup /> : null}*/}
            {true === menuStatus ? <PopupMenu /> : null}

            {scrollTop === true ? (
                <ScrollLink
                    to="wrapper"
                    smooth={true}
                    duration={500}
                    id="backToTop"
                    className="scroll-to-top showit"
                >
                    <i className="fa fa-angle-double-up"/>
                </ScrollLink>
            ) : null}
        </Fragment>
    );
};

export default PageContainer;
