import React from "react";
import {Col, Container, Row} from "react-bootstrap";


class ContactSocialMediaMenu extends React.Component {
    render() {
        return (
            <div className="artistMenu ">
                <Container>
                    <Row>
                        <Col>
                            <nav className="artistMenu text-center">
                                <ul>
                                    {this.props.data.artistPageMenu.map((links, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className={`${
                                                    undefined !== links.subItems
                                                        ? "menu-item-has-children"
                                                        : ""
                                                }`}
                                            >
                                                <a href={links.url} target="_blank">{links.name}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ContactSocialMediaMenu;