import React from "react";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import ServiceDetails from "../components/service-details";
import CallToActionOne from "../components/call-to-action-one";
import MenuContextProvider from "../context/menu-context";
import HeaderOne from "../components/header-one";
import Banner from "../components/banner";

class StudioRecording extends React.Component {
    state = {
        bannerh4: "Pre-Production",
        bannerh2: "",
        bannerImage: "/images/services/banner.png",
        serviceImage: "/images/services/studio.png",
        serviceDescriptionTitle: "Studio Recording",
        serviceDescription: "Working with Half House Studios is the perfect choice for anyone looking to take their studio recording to the next level. Our team of experienced engineers and producers specialize in a wide range of recording styles, genres, and instruments. We provide the highest quality sound engineering with exceptional attention to detail. Our cutting-edge equipment ensures that your recordings sound as professional as possible. Additionally, our production team is always available to help you achieve the sound you’re looking for. With our years of experience, you can rest assured that we will help you create the best recordings possible. So if you’re looking for top-notch studio recording, look no further than Half House Studios.",
        serviceName1: "Pre-Production",
        serviceLink1: "/services/preproduction",
        serviceName2: "Music Production & Writing",
        serviceLink2: "/services/productionandwriting",
        serviceName3: "Mix & Mastering",
        serviceLink3: "/services/mixandmaster",
        serviceName4: "Music & Promo Videos",
        serviceLink4: "/services/video",
        serviceName5: "Release Management",
        serviceLink5: "/services/management",
    }
    render() {
        return (
            <MenuContextProvider>
                <PageContainer>
                    <HeaderOne/>
                    <Banner data={this.state}/>
                    <ServiceDetails data={this.state}/>
                    <CallToActionOne extraClassName="ready"/>
                    <Footer/>
                </PageContainer>
            </MenuContextProvider>
        );
    };
}

export default StudioRecording;